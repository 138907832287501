import { Grid } from "@material-ui/core";
import React from "react";
import { GIVEAWAY_VERSION, POOL_IS_PRIVATE } from "../../../constants";
import ClaimConfigTable from "../Components/ClaimConfig/ClaimConfigTable";
import DurationTime from "../Components/DurationTimes";
import FreeTimeSetting from "../Components/FreeTimeSetting/FreeTimeSetting";
import useStyles from "../style";
import { UseCheckPermissions } from "../../../utils/useCheckPermissions";

const TabTimeSettings = (props: any) => {
  const {
    showTab,
    poolDetail,
    needValidateTime,
    setValue,
    errors,
    control,
    register,
    watch,
    token,
    setToken,
    getValues,
    needValidate,
    watchIsPrivate,
    isEdit
  } = props;
  const classes = useStyles();
  const poolType = watchIsPrivate ? Number(watchIsPrivate) : 0;
  const poolForCommunity =
    poolType === POOL_IS_PRIVATE.COMMUNITY ||
    poolType === POOL_IS_PRIVATE.EVENT;

  const isEditTimeSettings = UseCheckPermissions("EDIT_TIME_SETTING");
  const isEditMinimumPreorderTier = UseCheckPermissions("MINIMUM_TIER_FOR_PRE_PURCHASE");

  // const isGiveawayPool = false;
  const isGiveawayPool = poolDetail?.giveaway_version
    ? (watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") &&
      poolDetail?.giveaway_version === "giveaway_v2"
    : (watch("relationship_type") === "Giveaway" || watch("relationship_type") === "Giveaway (Labs Incubation)") &&
      GIVEAWAY_VERSION === "giveaway_v2";
  return (
    <div style={{ display: showTab ? "inherit" : "none" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.exchangeRate}>
          <DurationTime
            poolDetail={poolDetail}
            register={register}
            token={token}
            setToken={setToken}
            setValue={setValue}
            errors={errors}
            control={control}
            getValues={getValues}
            watch={watch}
            needValidate={needValidate}
            isEditDisabled = {!isEditTimeSettings}
            isEditMinimumPreorderTier = {isEditMinimumPreorderTier}
            isGiveawayPool = {isGiveawayPool}
            isEdit = {isEdit}
          />
        </Grid>
        <Grid item xs={6} className={classes.exchangeRate}>
          <div>
            <ClaimConfigTable
              poolDetail={poolDetail}
              setValue={setValue}
              register={register}
              watch={watch}
              errors={errors}
              control={control}
              getValues={getValues}
              isEditDisabled = {!isEditTimeSettings}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TabTimeSettings;
