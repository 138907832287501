import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import UserRow from "./UserRow";
import SearchForm from "./SearchForm";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import Grid from "@material-ui/core/Grid";
import {
  getBlockUserList,
  importBlockUsers,
} from "../../request/block-user";
import SetBlockUsersForm from "./SetBonusForm";

const tableHeaders = ["#", "WALLET ADDRESS", "POOL", "UPDATED AT", "ACTION"];

const BlockUsers: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isOpenBlockUserModal, setIsOpenBlockUserModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetBlockUserFileInput = (
    fileInputRef: React.MutableRefObject<null>
  ) => {
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.value = "";
    }
  };
  const getUserListInfo = async (query: any) => {
    const queryParams: any = {
      search_text: query,
      page: currentPage,
      limit: 10,
    };

    try {
      setLoading(true);
      const resObject = await getBlockUserList(queryParams);
      if (resObject.status === 200) {
        setUsers(resObject.data.data);
        setLastPage(resObject.data.lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListInfo(query);
    }, 500);
    return () => clearTimeout(timer);
  }, [query, currentPage]);

  const handleSelectCSVFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImportCSV = async () => {
    setIsLoading(true);
    try {
      const res = await importBlockUsers(selectedFile);
      if (res.status !== 200) {
        dispatch(alertFailure(res.message));
        return;
      }
      dispatch((res.status === 200 ? alertSuccess : alertFailure)(res.message));
      await getUserListInfo("");
      setQuery("");
    } catch (err: any) {
      dispatch(alertFailure("Something went wrong"));
    } finally {
      resetBlockUserFileInput(fileInputRef);
      setSelectedFile(null);
      setIsLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <>
        <>
          <div className={classes.header}>
            <Grid xs={12} md={6} className="header-left">
              <button
                className={classes.exportBtn}
                style={{ color: "#000", marginLeft: "10px" }}
                onClick={() => setIsOpenBlockUserModal(true)}
              >
                Block User
              </button>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label htmlFor="" style={{ padding: "10px", fontWeight: "600" }}>
                Import CSV file
              </label>
              <input
                color="primary"
                type="file"
                accept=".csv, .xlsx"
                onChange={handleSelectCSVFile}
                ref={fileInputRef}
              />
              <button
                className={classes.exportBtn}
                onClick={handleImportCSV}
                style={{ right: "60px", color: "#000" }}
                disabled={!selectedFile}
              >
                {isLoading ? (
                  <div className={classes.loader}></div>
                ) : (
                  "Import Users"
                )}
              </button>
            </Grid>
          </div>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "100%",
              marginTop: "15px",
              marginLeft: "12px",
            }}
          >
            <SearchForm seachValue={query} handleSearch={handleSearch} />
          </Grid>

          <TableContainer component={Paper} className={classes.tableContainer}>
            {loading ? (
              [...Array(10)].map((num, index) => (
                <div key={index}>
                  <Skeleton className={classes.skeleton} width={"100%"} />
                </div>
              ))
            ) : (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((tableHeader: string, index: number) => (
                      <TableCell key={index} className={classes.tableHeader}>
                        {tableHeader}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {users.map((user: any) => (
                    <UserRow
                      getBlockedUsersList={getUserListInfo}
                      key={user.id}
                      user={user}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
            {failure ? (
              <p className={classes.errorMessage}>{failure}</p>
            ) : (!users || users.length === 0) && !loading ? (
              <p className={classes.noDataMessage}>There is no data</p>
            ) : (
              <>
                {users && users.length > 0 && lastPage > 1 && (
                  <Pagination
                    page={currentPage}
                    className={classes.pagination}
                    count={lastPage}
                    onChange={handlePaginationChange}
                  />
                )}
              </>
            )}
          </TableContainer>

          <SetBlockUsersForm
            isOpen={isOpenBlockUserModal}
            setIsOpen={setIsOpenBlockUserModal}
            getUserListInfo={getUserListInfo}
            setQuery={setQuery}
          />
        </>
      </>
    </DefaultLayout>
  );
};

export default BlockUsers;
