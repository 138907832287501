import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { withRouter } from "react-router";

function GiveawayToggle(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail,register,watch } = props;
  const renderError = renderErrorCreatePool;


  useEffect(() => {
    if (poolDetail && poolDetail.linked_pool_togle !== undefined) {
      setValue("linked_pool_togle", !!poolDetail.linked_pool_togle);
    }
  }, [poolDetail, setValue]);

  useEffect(()=>{
    register('linked_pool_togle')
  },[register])



  return (
    <div
      style={{ marginBottom: "20px", marginTop: "20px", marginLeft: "10px" }}
    >
      <div style={{ display: "flex" }}>
        <label
          className={classes.formControlLabel}
          style={{ marginBottom: "0px" }}
        >
          Giveaway Toggle
        </label>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          width: "fitContent",
        }}
      >
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="linked_pool_togle"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (!confirm("Do you want change Toggle?")) {
                      return false;
                    }
                    await onChange(switchValue);
                  }}
                  checked={value}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              );
            }}
          />

          {errors?.is_display && (
            <p className={classes.formErrorMessage}>
              {renderError(errors, "linked_pool_togle")}
            </p>
          )}
        </FormControl>
      </div>
    </div>
  );
}

export default withRouter(GiveawayToggle);
